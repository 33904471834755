$primary-BackgroundColor: #662d91;
$font-family: Montserrat, Helvetica, sans-serif;
$font-size: 2.5em;

body {
    background-color: $primary-BackgroundColor !important;
}
.title {
    font-size: $font-size;
    font-family: $font-family;
    color: white;
    padding: 50px 0px 30px;
}
.Dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .DisplayList {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        min-height: 300px;
        min-width: 800px;

        .header {
            display: flex;
            .subTitle {
                position: relative;
                display: flex;
                justify-content: center;
                font-size: 1.4em;
                padding-bottom: 12px;
            }
            .addPost {
                position: absolute;
                right: 25%;
            }
        }
    }
}

.PostItem {
    border: 1px solid lightgray;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
    min-width: 800px;
    padding: 18px;
    div {
        display: flex;
        justify-content: space-between;
    }
    span {
        margin: 5px;
    }
    label {
        font-weight: bold;
        cursor: pointer;
    }
}

.ErrorComponent {
    color: black;
    font-weight: 600;
    font-size: larger;
    display: flex;
    justify-content: center;
    padding-top: 60px;
    span {
        color: red;
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}

.paginationItem {
    background: #fff;
    border: 2px solid #666;
    padding: 10px 15px;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    position: relative;
    margin: 0 5px;
    cursor: pointer;
}

.paginationItem span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.prev,
.next {
    background: #fff;
    border: none;
    padding: 10px;
    color: $primary-BackgroundColor;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    margin: 0 10px;
    cursor: pointer;
}

.paginationItem.active {
    border: 1px solid #888;
    color: #888;
    pointer-events: none;
}

.prev.disabled,
.next.disabled {
    pointer-events: none;
    box-shadow: none;
    color: #999;
}
#description {
    height: 150px;
}
